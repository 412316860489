.landing-header{
    padding: 25px 10%;
    display: flex;
    flex-direction: row;
    z-index: 2;

    .landing-header-nav-wrap{
        margin-left: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: default;

        .landing-header-nav-text{
            font-family: Montserrat Medium;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            color: #FFFFFF;
        }
    }
}

.landing-header-poper{
    margin-top: 10px;

    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
        background: rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(100px);
      }
    
    /* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
    @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
        background: #181B1C;
    }

    border-radius: 10px;
    overflow: hidden;
    z-index: 100;

    .tooltip{
        display: flex;
        flex-direction: column;
        
        .tooltip-item{
            padding: 13px 20px;
            align-items: center;
            display: flex;

            &:hover{
                background: rgba(255,255,255,0.1);
            }

            p{
                margin-left: 10px;
                font-family: Montserrat SemiBold;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                color: #FFFFFF;
            }
        }
    }
}