.landing-background{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    .landing-background-blobs-top{
        position: absolute;
        top: -200px;
        right: -200px;
        opacity: 0.4;
        z-index: 1;

        img{
            width: 784px;
            height: 897px;
        }
    }
}

@media screen and (max-width: 1200px) {
    .landing-background{
        display: none;
    }
}